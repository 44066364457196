<template>
  <v-row justify="center">
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="g_master_calon_siswa.dialog"
      max-width="350px"
    >
      <v-card outlined>
        <v-card-title class="title">{{ title }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row @click="insertManual" style="cursor: pointer" class="pt-4">
            <v-col cols="12" sm="10">{{ content1 }}</v-col>
            <v-col cols="12" sm="2">
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-col>
          </v-row>
          <v-divider v-if="type == 'student'" class="my-4"></v-divider>
          <v-row
            v-if="type == 'student'"
            @click="
              () => {
                $store.commit('TOGGLE_DIALOG_CALON_SISWA');
                $router.push({
                  path: 'upload-master-data',
                  query: { type: type }
                });
              }
            "
            style="cursor: pointer"
          >
            <v-col cols="12" sm="10">Upload Excel</v-col>
            <v-col cols="12" sm="2">
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-btn
              outlined
              small
              color="primary"
              @click="$store.commit('TOGGLE_DIALOG_CALON_SISWA')"
            >
              {{ $t("app.cancel") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    content1: {
      type: String,
      required: true
    },
    content2: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    type: String
  },
  computed: mapGetters(["g_master_calon_siswa"]),
  data() {
    return {
      dataUpload: null
    };
  },
  methods: {
    insertManual() {
      this.$store.commit("TOGGLE_DIALOG_CALON_SISWA");
      this.$router.push(this.path);
    }
  }
};
</script>
