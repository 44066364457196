var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"transition":"scale-transition","persistent":"","max-width":"350px"},model:{value:(_vm.g_master_calon_siswa.dialog),callback:function ($$v) {_vm.$set(_vm.g_master_calon_siswa, "dialog", $$v)},expression:"g_master_calon_siswa.dialog"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"pt-4",staticStyle:{"cursor":"pointer"},on:{"click":_vm.insertManual}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_vm._v(_vm._s(_vm.content1))]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-right")])],1)],1),(_vm.type == 'student')?_c('v-divider',{staticClass:"my-4"}):_vm._e(),(_vm.type == 'student')?_c('v-row',{staticStyle:{"cursor":"pointer"},on:{"click":() => {
              _vm.$store.commit('TOGGLE_DIALOG_CALON_SISWA');
              _vm.$router.push({
                path: 'upload-master-data',
                query: { type: _vm.type }
              });
            }}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_vm._v("Upload Excel")]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-right")])],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$store.commit('TOGGLE_DIALOG_CALON_SISWA')}}},[_vm._v(" "+_vm._s(_vm.$t("app.cancel"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }